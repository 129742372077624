<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>订单中心</el-breadcrumb-item>
              <el-breadcrumb-item @click.native="goto()">发票中心</el-breadcrumb-item>
              <el-breadcrumb-item>申请纸质发票</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_rights">
                <div class="box_right_top">纸质发票</div>
                <div class="box_right_cent">
                  <div class="paper-box">
                    <div class="paper-top">选择要开发票的商品</div>
                    <div class="invoice-cent">
                        <el-table
                        :data="tableData"
                        border :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                        :cell-style="{ textAlign: 'center' }"
                        style="width: 100%;text-align: center;"
                        @selection-change="handleSelectionChange">
                            <el-table-column
                              type="selection"
                              width="60px">
                            </el-table-column>
                            <el-table-column
                            prop="id"
                            label="商品编号"
                            width="120px">
                            </el-table-column>
                            <el-table-column
                            prop="infor"
                            label="商品"
                            width="450px">
                            <template slot-scope="{ row }">
                                <div v-if="Array.isArray(row.infor)">
                                    <div v-for="(adr, index) in row.infor"
                                        :key="index"
                                        class="rowBox flex-center"
                                    >
                                        <img style="width:120px" :src="adr.img" alt="" />
                                        <p>{{ adr.name }}</p>
                                    </div>
                                </div>
                                <span v-else>{{ row.infor }}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="price"
                            label="单价">
                            </el-table-column>
                            <el-table-column
                            prop="number"
                            label="数量">
                            </el-table-column>
                            <el-table-column
                            prop="subtotalPrice"
                            label="小计">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="paper-address">邮寄地址</div>
                    <div class="form_box">
                      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                        <el-form-item label="收货人" prop="name">
                          <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="所在地区" prop="selectedOptions">
                          <el-cascader
                            :options="options"
                            ref="cascaderAddr"
                            v-model="ruleForm.selectedOptions"
                            @change="handleChange"
                          >
                          </el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址" prop="company">
                          <el-input v-model="ruleForm.company"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="number">
                          <el-input v-model="ruleForm.number"></el-input>
                        </el-form-item>
                        <el-form-item label="固定电话">
                          <el-input v-model="ruleForm.tell" style="width:20%" @change="handleSearchEvent"></el-input>
                          <span style="margin:0 10px">-</span>
                          <el-input v-model="ruleForm.tellpone" style="width:75%" @change="handleEvent"></el-input>
                        </el-form-item>
                        <el-form-item >
                          <div class="flex-start">
                            <div class="newly_btn" @click="newlypaper()">确定</div>
                            <div class="newly_Btn" @click="newlyBtn()">取消</div>
                          </div>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { applygoods,applypaper } from "@/api/order/aftersale"
import { phoneReg } from '@/utils/regular'
let pcas = require("@/assets/json/pca-code.json");
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        options: pcas,
        ruleForm: {
          name: '',
          number:'',
          selectedOptions: [],
          company:"",
          tell:'',
          tellpone:''
        },
        rules: {
          name: [
            { required: true, message: '请输入收货人', trigger: 'blur' },
          ],
          number: [
            { required: true, message: '手机号不能为空', trigger: 'blur' },
            { pattern: phoneReg, message: "请输入正确的手机号码"}
          ],
          selectedOptions: [
            { required: true, message: '请选择所在地址', trigger: 'blur' },
          ],
          company: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
          ],
        },
        tableData: [],
        orderGoodsIdList:[],
        orderNo:0,//订单编号
        invoiceId:'',//发票id
      }
  },
  created(){
    this.invoiceId=this.$route.query.invoiceId
    this.orderNo=this.$route.query.orderNo
    let data={
      "orderNo": this.orderNo,
      "type": 1
    }
    applygoods(data).then((data)=>{
      if(data && data.code=="00000"){
        data.data.forEach(item => {
          let obj = {};
          let objs = {};
          let infor = [];
          objs.img = item.goodsPic;
          objs.name = item.goodsName;
          infor.push(objs);
          obj.infor = infor;
          obj.id = item.id;
          obj.price = item.price;
          obj.number = item.number;
          obj.subtotalPrice = item.subtotalPrice;
          this.tableData.push(obj);
        });
      }

    })
  },
  methods:{
    handleSelectionChange(val) {
      if(val.length==0){
        this.orderGoodsIdList=[]
      }else{
        val.forEach((item,index)=>{
          this.orderGoodsIdList[index]=item.id
        })
      }
    },
      //地址选择
    handleChange(e, form, thsAreaCode) {
        thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels// 注意2： 获取label值
        console.log(thsAreaCode) // 注意3： 最终结果是个一维数组对
      // console.log(thsAreaCode[0] + thsAreaCode[1] + thsAreaCode[2])
    },
    //申请纸质发票
    newlypaper(){
      this.$refs['ruleForm'].validate((valid) => {
        if(valid){
          let thsAreaCode=this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels
          let receiverAddress=thsAreaCode[0]+thsAreaCode[1]+thsAreaCode[2]+this.ruleForm.company
          let phone=''
           if(this.ruleForm.tell!=''&&this.ruleForm.tellpone!=''){
            phone =this.ruleForm.tell+'-'+this.ruleForm.tellpone
          }
          if(this.orderGoodsIdList.length!=0){
            if(this.ruleForm.tell!=''&& this.ruleForm.tellpone==''){
              this.$message({
                type: "error",
                message: '请输入座机号码',
              });
            }else{
              let data={
                "invoiceId": this.invoiceId,
                "invoiceType": 2,
                "orderGoodsIdList":this.orderGoodsIdList,
                "receiverAddress": receiverAddress,
                "receiverName":this.ruleForm.name,
                "receiverPhone": this.ruleForm.number,
                "fixedTelephone":phone
              }
              applypaper(data).then((data)=>{
                if(data.code=='00000'){
                  this.$message({
                      type: 'success',
                      message: '申请成功!'
                  });
                  this.$router.go(-1)
                  // this.$router.push({ name: 'InvoiceDetails'})
                }else{
                  this.$message({
                    type: "error",
                    message: data.desc,
                  });
                  
                }
              })
            }
          }else{
            this.$message.error('请选择要开发票的商品');
          }
          
          
        }
      })
      
    },
    //取消纸质发票
    newlyBtn(){
      this.$router.go(-1)
      // this.$router.push({ name: 'InvoiceDetails'})
    },
    goto(){
      this.$router.push({ name: 'InvoiceCenter'})
    },
    handleEvent(val){
      // if(this.ruleForm.tell!=''){
        // if(this.ruleForm.tellpone!=''){
          let tell =  /^(^[0-9]{7,8}$)/;
          if (!tell.test(val)) {
            this.$message({
              type: "error",
              message: '请输入正确的固定号码(7-8)',
            });
          }else{
            this.$message({
              type: "error",
              message: '请输入座机号码',
            });
          }
      // }
      
    },
    handleSearchEvent(val){
      let tel =  /^(^0[1-9]{1}[0-9]{1,2}$)/;
      if (!tel.test(val)) {
        this.$message({
          type: "error",
          message: '请填写正确区号',
        });
      }
      
    }

  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rights{
    width: 87%;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .paper-box{
      padding: 15px 20px;
      font-size: 15px;
      .paper-top{
        margin-bottom: 15px;
      }
      .paper-address{
        margin: 20px 0;
      }
      .form_box{
        .el-form-item__content{
          width: 50%;
          .el-cascader{
            width: 100%;
            .el-input--suffix{
              width: 100%;
            }
          }
        }
        .el-form-item__label{
          float: none;
        }
        .newly_btn{
          color: #fff;
          background: #CD9F49;
          width: 78px;
          height: 28px;
          line-height: 28px;
          border-radius: 5px;
          text-align: center;
        }
        .newly_Btn{
          width: 78px;
          height: 28px;
          line-height: 28px;
          border-radius: 5px;
          text-align: center;
          border: 1px solid #333;
          margin-left: 15px;
        }
      }
    }
    
  }
}
</style>